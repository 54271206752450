import { Stack, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import svLocale from 'date-fns/locale/sv';

export default function FormDatePicker(props: any) {

    const { name, label, value, onChange } = props


    const convertToDefEventPara = (name: string, value: any) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} margin="normal" locale={svLocale}>
            <Stack spacing={3}>
                <MobileDatePicker
                    label={label}
                    // name={name}
                    inputFormat="yyyy/MM/dd"
                    value={value}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(date: any) => onChange(convertToDefEventPara(name,date))}
                    maxDate = {new Date()}
                />
            </Stack>
        </LocalizationProvider>
    )
}
