import { LoginRequest } from "../models/login.model";
import { http } from "./http";
import {AUTH_STORAGE_USER} from '../global/constants';


export default class LoginService {

    login(loginData: LoginRequest): Promise<boolean> {
        return http.post<any>("/api/Token/Authenticate", loginData)
            .then((response) => {
                if (response.data) {
                    sessionStorage.setItem(AUTH_STORAGE_USER, response.data);
                    return response.data;
                }
            });
    }
    logout() {
        sessionStorage.removeItem(AUTH_STORAGE_USER);
    }
}