import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import './PortObjectSearch.css';
import '../services/http.ts';
import { PortObject } from '../models/portObject.model';
import { getPortObjects } from '../services/portObject.service';


export default function Asynchronous(props: any) {

    const { name, label, value, onChange, error } = props;

    const convertToDefEventPara = (name: string, value: number | undefined) => ({
        target: {
            name, value
        }
    })

    let defaultOption: PortObject = 
    {
        id: 0,
        number: "",
        name: "",
        description: "",
        active: false,
        drivingProhibition: false
    }

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<readonly PortObject[]>([]);
    const [selectedOption, setSelectedOption] = React.useState<PortObject>(defaultOption);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        if (value === undefined) {
            setSelectedOption(defaultOption);
        }
    }, [value]);

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {

            let portObjects: PortObject[] = [];
            portObjects.push(defaultOption);
            let data = await getPortObjects();

            portObjects.push(...data);

            if (active) {
                setOptions(portObjects);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            className='portObjectSearch'
            id="asynchronous-demo"
            style={{ display: "flex" }}
            value={selectedOption}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(portObject) => ((portObject.number ?? '') + (portObject.name ? ' ' + portObject.name : ''))}
            options={options}
            key={selectedOption.id}
            loading={loading}
            onChange={(event, value: any) => {onChange(convertToDefEventPara(name, value.id)); setSelectedOption(value)}}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={16} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}