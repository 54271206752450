import { useState } from 'react';

export function useForm(initialValues: any, validateOnChange = false, validate: any) {

    let intialErrors: any = {};
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState(intialErrors);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialValues);
        setErrors({})
    }


    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    }
}



