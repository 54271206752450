import React from "react";
// import "./styles.css";
import './App.css';
import FaultReportForm from './components/FaultReportForm';
import { LoginRequest } from "./models/login.model";
import LoginService from "./services/login.service";

interface Props { }

interface State {
  authorized: boolean;
  loginDetails: LoginRequest
}

export default class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      authorized: false,
      loginDetails: {} as LoginRequest
    };
  }
  render() {

    if (this.state.authorized) {
      return (
        <div className="App-header">
          <FaultReportForm {...this.state.loginDetails}/>
        </div>
      );
    }
    else {
      return (<div></div>)
    }
  }
  componentDidMount() {
    window.addEventListener(
      'message',
      (event) => {
        this.checkIfCorsAllowed(event);
      },
      {once: true}
      
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      'message',
      (event) => {
        this.checkIfCorsAllowed(event);
      }
    );
    let loginService= new LoginService();
    loginService.logout();
  }

  checkIfCorsAllowed(event: MessageEvent) {
    var eventData = JSON.parse(event.data);
    this.setState({
      authorized: event.origin === process.env.REACT_APP_LOCATION_HOSTNAME,
      loginDetails: {userName: eventData.u, password: eventData.p, authKey: eventData.k} as LoginRequest
    
    });
  }
}