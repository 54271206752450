import * as React from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import PortObjectSearch from './PortObjectSearch';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { FaultReportInput } from '../models/faultReportInput.model';
import { useForm } from '../services/useForm';
import { createFaultReport } from '../services/faultReport.service';
import FormDatePicker from './FormDatePicker';
import LoginService from '../services/login.service';
import { LoginRequest } from '../models/login.model';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialValues: FaultReportInput = {
  employeeNo: '',
  portObjectId: undefined,
  reportDate: new Date(),
  description: '',
  drivingProhibition: false
}

function FaultReportForm(loginRequest: LoginRequest) {

  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors }

    if ('employeeNo' in fieldValues) {
      temp.employeeNo = fieldValues.employeeNo === "";
    }

    if ('portObjectId' in fieldValues) {
      temp.portObjectId = fieldValues.portObjectId === undefined;
    }

    if ('description' in fieldValues) {
      temp.description = fieldValues.description.length === 0;
    }

    setErrors({
      ...temp
    });

    if (fieldValues === values) {
      return Object.values(temp).every(x => x === false)
    }
  }

  const {
    values,
    // setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialValues, true, validate);

  const handleSubmit = (e: any) => {
    e.preventDefault()
    let faultReport: FaultReportInput = {
      employeeNo: values.employeeNo,
      portObjectId: values.portObjectId,
      reportDate: values.reportDate,
      description: values.description,
      drivingProhibition: values.drivingProhibition,
    };

    if (validate()) {
      handleFaultReportPost(faultReport);
    };
  }

  React.useEffect(() => {
    let loginService = new LoginService();

    (async (): Promise<any> => {
      return await loginService.login({
        userName: loginRequest.userName,
        password: loginRequest.password,
        authKey: loginRequest.authKey
      } as LoginRequest)
        .catch(error => {
          setErrorMessage("Obehörig. Kontakta administratör");
          setOpenError(true);
        }
        );
    })
      ();
  }, []);

  const handleFaultReportPost = (faultReport: FaultReportInput) => {
    (async () => {
      let res = await createFaultReport(faultReport);

      if (res !== null && res !== undefined) {
        if (res.status === 200) {
          setOpen(true);
          setErrorMessage('');
          resetForm();
        }
        else {
          setErrorMessage(res.data.Message);
          setOpenError(true);
        }
      }
      else {
        setOpenError(true);
        setErrorMessage("Kontakta administratör");
      }
    })();
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setOpenError(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { width: '25ch' },
        height: '10%'
      }}
    >
      <TextField id="standard-basic"
        label="Anställningsnummer"
        name="employeeNo"
        variant="outlined"
        margin="normal"
        value={values.employeeNo}
        onChange={handleInputChange}
        error={errors.employeeNo} 
        sx={{ mb: "20px"}}/>

      <PortObjectSearch
        label="Objektnummer / namn"
        name="portObjectId"
        onChange={handleInputChange}
        value={values.portObjectId}
        error={errors.portObjectId} 
        sx={{ mb: "0px"}}/>

      <FormDatePicker
        label="Felet uppkom"
        value={values.reportDate}
        name="reportDate"
        onChange={handleInputChange} 
        />

      <TextField
        id="outlined-multiline-static"
        label="Felbeskrivning"
        name="description"
        fullWidth={true}
        multiline={true}
        InputLabelProps={{
          shrink: true
        }}
        minRows={3}
        placeholder="Mata in felbeskrivning..."
        margin="normal"
        value={values.description}
        onChange={handleInputChange}
        error={errors.description} />

      <FormControlLabel label="Begränsningar i nyttjandet" control={
        <Checkbox
          name="drivingProhibition"
          onChange={(event, value: any) => handleInputChange({ target: { name: "drivingProhibition", value: value } })}
        />
      } />
      <FormControl margin="normal">
        <Button variant="contained" onClick={handleSubmit}>Registera</Button>
        <Snackbar open={open} autoHideDuration={2500} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%', alignContent: 'center' }}>
            Felrapport sparad
          </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={2500} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%', alignContent: 'center' }} >
            {errorMessage}
          </Alert>
        </Snackbar>
      </FormControl>
    </Box>
  );

}

export default FaultReportForm;
