// users.service.ts
import { PortObject } from "../models/portObject.model";
import { http } from "./http";

export const getPortObjects = async (): Promise<PortObject[]> => {

  const { data } = await http.get<PortObject[]>("/api/PortObject/GetAllActive");

  return data;
};

export const postPortObject = async (portObject: PortObject): Promise<PortObject> => {
  const { data } = await http.post<PortObject>("/PortObject", portObject);
  return data;
};

export const updatePortObject = async (portObject: PortObject): Promise<PortObject> => {
  const { data } = await http.put<PortObject>(`/PortObject/${portObject.id}`, portObject);
  return data;
};

export const deleteUser = async (portObject: PortObject): Promise<PortObject> => {
  const { data } = await http.delete<PortObject>(`/PortObject/${portObject.id}`);
  return data;
};